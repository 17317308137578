
.product-info-content p {
    color: white;
}

.product-details > h1 {
    color: white;
}


.product-info-header {
    height: fit-content;
    gap: 5rem;
    padding: 2rem;
    border-radius: .8rem;
    display: flex;
    align-items: start;
}

.product-image {
    position: relative;
    width: 68.3rem;
    height: 46.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4rem 4rem 0rem 4rem;
    background-color: white;
}


.product-details {
    display: flex;
    flex-direction: column;
    margin-top: -1rem;
    gap: 2rem;
}

.product-name {
    font-weight: bold;

}

.product-line-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 2rem;
    gap: 1rem;
}

.product-line-container::after {
    content: "";
    bottom: 0;
    left: -5rem;
    position: absolute;
    width: calc(100% + 5rem);
    height: .5rem;
    background-color: #3E3936;
}

.product-line {
    color: white;

    text-transform: uppercase;
}


.recommended-use-img-container {
    display: flex;

    gap: 2rem;
}

.recommended-use-img-container * {
    width: 5rem;
    height: 5rem;
    fill: white;
}


.consultar-btn {
    display: flex;

    padding: 1rem 2.5rem;
    width: fit-content;
    background-color: #25D366;
    color: white;
    border-radius: 2.5rem;
    text-decoration: none;
    align-items: center;
    gap: 1.5rem;
}

.whatsApp-icon {
    height: 3rem;
    width: 3rem;
}

.product-info-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70vw;
    height: fit-content;
    padding: 4rem;

}

.product-info-body > li > h2 {
    color: white;
    margin-bottom: 2rem;
}

.product-info-body li {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 3rem;
    width: 50%;
    height: 50rem;

}


@media screen and (min-width: 350px) {

    .product-info-content span,
    .product-info-content a,
    .product-info-content p {
        font-size: var(--small-font-size);
    }

    .product-info-content strong {
        color: white;
        font-size: var(--small-header-size);
    }

    .product-info-content h2 {
        font-size: var(--x-small-header-size);
    }

    .product-info-content h1 {
        font-size: var(--small-header-size);
    }

    .product-prestige > .cls-1 {
        fill: white !important;
    }

    .background-img {
        top: 10vh;
    }

    .product-info-header {
        margin-top: 5rem;
        flex-direction: column;
    }

    .product-image {
        width: 90vw;
        height: fit-content;
    }

    .product-image img {
        height: 50rem;
        width: 50rem;
    }

    .product-prestige {
        height: 4rem;
        fill: black;
        position: absolute;
        bottom: 4%;
        right: 1%;
    }

    .ola-vertical {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 12rem;
        height: 12rem;
    }


    .product-line-container::after {
        bottom: -2rem;
        width: calc(70% + 15rem);
        height: 1rem;
        background-color: #3E3936;
    }

    .laboratory-and-recommended-use-container {
        width: 95vw;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .recommended-use {
        grid-column: 1/3;
        grid-row: 1/2;
    }

    .recommended-use-img-container {
        grid-column: 1/3;
        grid-row: 2/3;
    }

    .laboratory {
        grid-column: 3/4;
        grid-row: 1/2;
    }


    .laboratory-name {
        grid-column: 3/4;
        grid-row: 2/3;
    }

    .content {
        grid-column: 4/5;
        grid-row: 1/2;
    }

    .product-content {
        grid-column: 4/5;
        grid-row: 2/3;
        justify-self: center;
    }

    .product-info-body {
        width: 100vw;
        padding: 3rem;

    }

    .product-info-body li {
        width: 100%;
        height: fit-content;
    }

}

@media screen and (min-width: 750px) {

    .product-info-header {
        width: 90vw;
        flex-direction: row;
        justify-content: center;

    }

    .product-image {
        width: 68.3rem;
        height: 46.4rem;
    }

    .product-image img {
        width: 40.1rem;
        height: 40.1rem;
    }

    .product-prestige {
        height: 4rem;
        position: absolute;
        bottom: 4%;
        right: 1%;
    }

    .ola-vertical {
        position: absolute;
        height: 12rem;
        width: 12rem;
        bottom: 0%;
        right: 0%;
        fill: #99be14;
    }

    .blacked {
        fill: black;
    }

    .laboratory-and-recommended-use-container {
        width: fit-content;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    .product-info-body {
        width: 90vw;
    }

    .product-info-body li {
        width: 50%;
    }

    .content {
        display: none;
    }
}

@media screen and (min-width: 1300px) {

    .product-info-content span,
    .product-info-content a,
    .product-info-content p {
        font-size: var(--medium-font-size);
    }

    .product-info-content strong {
        color: white;
        font-size: var(--large-font-size);
    }

    .product-info-content h2 {
        font-size: var(--small-header-size);
    }

    .product-info-content h1 {
        font-size: var(--medium-header-size);
    }

}

@media screen and (min-width: 1900px) {

    .product-info-content span,
    .product-info-content a,
    .product-info-content p {
        font-size: var(--x-large-font-size);
    }


    .product-info-content h2 {
        font-size: var(--medium-header-size);
    }

    .product-info-content h1 {
        font-size: var(--x-large-header-size);
    }

}



