.phone {
    display: none;
}

.shopping-car {
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 100%;
    height: fit-content;
    padding: 0;
}

/*
.shopping-car-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 20%);
    z-index: 9;
}
*/

.shopping-car-disabled {
    pointer-events: none;
    filter: brightness(85%);
    transition: .3s all;
}

.shopping-car-products-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1.5rem;
    width: 115.0rem;
    height: 83.3rem;
    margin-left: 6.7rem;
    margin-top: 19.5rem;
    margin-bottom: 19.5rem;
    overflow: auto;
    border-radius: 5rem;
    background-color: rgba(255, 255, 255);
}

.shopping-car-products-container::-webkit-scrollbar {
    width: 1rem;
    background-color: #f1f1f1;
    color: black;
}

.shopping-car-products-container::-webkit-scrollbar-thumb {
    background-color: #99be14;
    height: 2rem;
    border-radius: 5rem;
}

.shopping-car-header-container {
    display: flex;
    width: fit-content;
    align-self: start;
    align-items: baseline;
    height: fit-content;
    margin-top: 4.8rem;
    margin-left: 4.3rem;
    gap: 4.3rem;
}

.shopping-car-header-container > span {
    width: fit-content;
    /*font-size: 2rem;*/
    padding: 1.1rem 1.74rem;
    border-radius: 5rem;

}

.shopping-car-header {
    /*font-size: 4rem;*/
    font-weight: normal;
    color: black;
    text-align: start;
}

.availability-disclaimer {
    /*font-size: 1.5rem;*/
    width: 70%;
    margin-left: 4.3rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid black;
    align-self: flex-start;
}

.shopping-car-header-container > span {
    border: 1px solid black;
}

.shopping-car-header-container > span:hover {
    cursor: pointer;
}

.shopping-car-header-container > a {
    /*font-size: 2rem;*/
    padding: 1.1rem 1.74rem;
    border-radius: 5rem;
    text-decoration: none;
    background: var(--on-hover-color);
    color: white;
}

.shopping-car-header-container > a:hover {
    cursor: pointer;
}

.shopping-car-list-container {
    display: flex;
    flex-direction: column;

}

.delete-alarm {
    transition: .3s all;
    width: fit-content;
    position: fixed;
    text-align: center;
    border-radius: 0 0 1rem 1rem;
    left: 50%;
    padding: 1.5rem 3rem;
    /*font-size: 2rem;*/
    z-index: 999;
    background-color: indianred;
    color: white;
    transform: translate(-50%, -100%);
}

.product-in-shopping-car {
    display: flex;
    flex-direction: column;
    width: 91rem;
    height: fit-content;
    border-radius: 1rem;
    margin-left: 10.2rem;
    margin-bottom: 4rem;
    padding-bottom: 1rem;
    overflow: hidden;
}


.product-header-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 6rem;
    width: 100%;
    cursor: pointer;
}

.product-header-productline {
    /*font-size: 2.7rem;*/
    color: var(--on-hover-color);
    margin-left: 4rem;
}

.product-header-quantity {
    /*font-size: 2.7rem;*/
}

.sc-arrow {
    margin-left: 1.5rem;
    height: 1.8rem;
    transform: rotate(0deg);
    transition: .3s all;
}

.sc-arrow-active {
    transform: rotate(-180deg);
    fill: var(--on-hover-color);
}


.purchase-body {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(9, 1fr);
    align-items: center;
    width: 88.2rem;
    max-height: 21.8rem;
    border-radius: 5rem;
    box-shadow: var(--box-shadow-down);
    transition: all 0.3s ease-in-out;
}

.cart-product-hide {
    max-height: 0;
    overflow: hidden;
}

.purchase-img {
    grid-row: 1/10;
    grid-column: 1/2;
    height: 19.1rem;
    width: 19.1rem;
}

.purchase-product-details {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    margin-left: 3rem;
    gap: 1rem;
    height: 100%;
    width: fit-content;
}

.purchase-name {
    grid-row: 2/4;
    grid-column: 2/5;
    /*font-size: 2.7rem;*/
    color: black;
}

.show-product-details {
    text-decoration: none;
    grid-row: 4/6;
    grid-column: 2/4;
    /*font-size: 2rem;*/
}

.purchase-inputs {
    grid-row: 6/8;
    grid-column: 2/3;
    justify-self: start;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    gap: .5rem;
    border: 1px solid black;
    border-radius: 5rem;
}

.purchase-weight-type-input {
    background-color: #f1f1f1;
    /*font-size: 2rem;*/
    width: fit-content;
    height: 3.5rem;
    padding: .5rem 2rem;
    border-radius: 4px;
}

.purchase-quantity-input {
    position: relative;
    height: 4.5rem;
    width: 12.7rem;
    text-align: center;
    /*font-size: 1.5rem;*/
    padding: .5rem .5rem .5rem 1rem;
}

.plus {
    cursor: pointer;
    width: 2.5rem;
    padding: .5rem;
}

.shopping-car-delete-icon.cls-1.cls-2 {
    cursor: pointer;
    width: 2.5rem;
    padding: .5rem;
    fill: white;
    stroke: black;

    stroke-width: 3px;
}

.shopping-car-delete-icon:hover {


}

.subtract {
    cursor: pointer;
    width: 2.5rem;
    padding: .5rem;
}

.number-of-content {
    max-width: 50%;
    /*font-size: 1.5rem;*/
    color: black;
    background-color: white;
    padding: .5rem;
    border-radius: .5rem;
    cursor: default;
}


.update-quantity-button {
    transition: .3s all;
    width: 2rem;
    height: 2rem;
    border-radius: 10%;
    align-self: center;
    border: none;
    background-color: white;
    color: #99be14;
}

.update-quantity-button-NaN {
    display: none;
}

.update-quantity-button:hover {
    background-color: #99be14;
    color: white;
}

.cancel-update-quantity-button {
    transition: .3s all;
    width: 2rem;
    height: 2rem;
    border-radius: 10%;
    align-self: center;
    border: none;
    background-color: white;
    color: indianred;
    margin-left: .5rem;
}

.cancel-update-quantity-button:hover {
    background-color: indianred;
    color: white;
}

.purchase-details-last-row {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: fit-content;
}

.purchase-delete {
    position: absolute;
    width: fit-content;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    /*font-size: 1.8rem;*/
    color: lightgray;
    cursor: pointer;
}

.purchase-price-details {
    grid-row: 1/10;
    grid-column: 3/6;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
    align-self: center;
    margin: 0 3rem 0 auto;
}

.purchase-price-details {
    /*font-size: 2rem;*/
    text-align: end;
}

.purchase-total-container {
    position: relative;
}

.purchase-total-container > span:nth-of-type(1) {
    font-weight: bold;
}

.price-to-mxn {
    position: absolute;
    width: fit-content;
    /*font-size: 1.5rem;*/
    bottom: 0;
    right: 0;
    transform: translate(-0%, 100%);
}

span.purchase-price, span.purchase-total {
    /*font-size: 2.7rem;*/
    color: var(--original-dark-green);
    text-align: end;
}

.large-line {
    height: 1px;
    width: 38.5rem;
    background-color: black;
}

.purchase-total {
    margin-left: 1rem;
}

a.disabled-button {
    transition: .3s all;
    pointer-events: none;
    background-color: white;
    color: black;
}

a.disabled-button:hover {
    cursor: not-allowed;
}

.disabled-button img {
    display: none;
}

.order-button {
    /*font-size: 2rem;*/
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--original-dark-green);
    width: 100%;
    padding: 1rem;
    color: black;
    border: none;
    transition: all .3s;
    border-radius: 5rem;
    box-shadow: var(--box-shadow-down);
    -webkit-box-shadow: var(--box-shadow-down);
    -moz-box-shadow: var(--box-shadow-down);
}

.order-button img {
    width: 3rem;
    margin-right: 1.5rem;
}

.order-button:hover {
    color: white;
    cursor: pointer;
}


/*Formulario*/

.purchase-summary {
    display: flex;
    flex-direction: column;
    width: 52.9rem;
    height: 37rem;
    padding: 3rem;
    gap: 4rem;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5rem;
    margin-top: 19.5rem;
    z-index: 99;
}

.purchase-summary > h2 {
    font-weight: 550;
}

.purchase-summary-total {
    display: flex;
    justify-content: space-between;
    padding: 0 5rem;
}

.purchase-summary-total > :first-child {
    /*font-size: 2.4rem;*/
}

.purchase-summary-total > span:nth-of-type(2) {
    color: var(--original-light-green);
    /*font-size: 2.7rem;*/
}

.purchase-summary-legend {
    text-align: left;
    align-self: center;
    width: 80%;
    /*font-size: 1.3rem;*/
}

.purchase-summary > button {
    width: 38.8rem;
    height: fit-content;
    align-self: center;
    border-radius: 5rem;
    color: white;
    border: none;
    text-transform: uppercase;
    background-color: var(--original-dark-green);
}

.purchase-summary > button.disabled-button {
    background-color: gray;
    cursor: default;
    pointer-events: none;
}

.user-info-form {
    border-radius: 5rem;
    margin-top: 19.5rem;
    margin-left: 10rem;
    display: flex;
    flex-direction: column;
    width: 52.9rem;
    height: fit-content;
    padding: 3rem;
    gap: 4rem;
    background-color: rgba(255, 255, 255, 1);
    z-index: 99;
}

.user-info-form > input {
    height: 3.5rem;
    width: 100%;
}

.user-info-form input,
.user-info-form textarea {
    padding-left: 2.5rem;
}


.user-info-form-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    gap: 4rem;
}

.back-button {
    width: 2.5rem;
    stroke-width: 2px;
    position: absolute;
    top: -1.5rem;
    right: 0;
    transition: .1s all;
}

.back-button:hover {
    cursor: pointer;
    color: var(--original-light-green);

}

.user-info-form textarea {
    height: 10rem;
    max-height: 12rem;
    width: 100%;
    border-radius: 5rem;
    max-width: 100%;
    padding-top: 2rem;
    border: none;
    box-shadow: var(--box-shadow-down);
    -webkit-box-shadow: var(--box-shadow-down);
    -moz-box-shadow: var(--box-shadow-down);
}

.user-info-form span > small {
    /*font-size: 1.5rem;*/
}

.purchase-summary-container {
    margin-left: 10rem;
}

.user-info-form h2,
.purchase-summary-container h2 {
    color: black;
    /*font-size: 4rem;*/
    padding-bottom: 2.5rem;
    align-self: center;
    width: fit-content;
    margin: 0;
    border-bottom: 1px solid black;
}


.user-info-form-input-options {
    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.cliente-nuevo-option {
    display: flex;
    align-items: center;
    justify-content: start;
    /*font-size: 2.5rem;*/
    gap: 1rem;
}


/*Formularios*/

.clientForm {
    display: flex;
    flex-direction: column;
    gap: 3.5rem;

}

.clientForm span {
    display: block;
    /*font-size: 2.5rem;*/
}

.clientForm > input {

    height: 5rem;
    width: 100%;
    border-radius: 5rem;
    box-shadow: var(--box-shadow-down);
    -webkit-box-shadow: var(--box-shadow-down);
    -moz-box-shadow: var(--box-shadow-down);
}

.clientForm .submit-button {
    margin-top: 4rem;
    transition: .3s all;
    background-color: white;
    color: black;
    border: none;
    padding: 1rem;
}

.clientForm .submit-button:hover {
    background-color: #99be14;
    color: white;
    cursor: pointer;
}

@media screen and (min-width: 350px) and (max-width: 749px) {

    .shopping-car small {
        font-size: var(--small-font-size);
        font-weight: lighter;
    }

    .shopping-car span,
    .shopping-car p,
    .shopping-car a,
    .shopping-car label,
    .shopping-car button {
        font-size: var(--medium-font-size);
    }

    .shopping-car h2 {
        font-size: var(--small-header-size);
    }

    .shopping-car h1 {
        font-size: var(--medium-header-size);
    }

    main.shopping-car {
        background-color: white;
        gap: 0;
    }

    .shopping-car-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 85%);
        z-index: 999;
    }

    .desktop {
        display: none;
    }

    .phone {
        display: inherit;
    }

    .shopping-car-header-container {
        padding-left: 2.4rem;
        margin: 0;
        width: 100%;
        border: none;
    }

    .shopping-car-header-container > a {
        text-align: center;
        width: 32rem;
        height: 6.2rem;
    }

    .availability-disclaimer {
        width: 80%;
    }

    .shopping-car-products-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background: white;
        border-bottom: 2px solid white;
        border-radius: 0;
        margin: 0;
        width: 100vw;
        padding-top: 10vh;
    }

    .price-to-mxn {
        /*font-size: 2rem;*/
    }

    .shopping-car {
        flex-direction: column;
        align-items: center;
        height: 100vh;
        width: 100vw;
        gap: 5rem;
        object-fit: cover;
        background-color: rgba(255, 255, 255, .2);
    }

    .shopping-car-products-container {
        align-items: center;
    }

    .update-alarm, .delete-alarm {
        width: 60vw;
    }

    .shopping-car-products-container > div:last-of-type {
        margin-bottom: 5rem;
    }

    .user-info-form {
        position: absolute;
        margin: 0;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        z-index: 9999;
    }


    .product-in-shopping-car {
        align-self: center;
        padding-bottom: 2rem;
        width: 68rem;
        margin: 0;
        height: fit-content;
        border-radius: 5rem;
        margin-bottom: 15rem;
    }


    .product-header-container {
        width: 100%;
    }

    span.product-header-productline {
        /*font-size: 4rem;*/
        font-size: var(--large-font-size);
        margin: 0;
    }

    .product-header-quantity {
        /*font-size: 2.4rem;*/
    }


    .purchase-summary-total > :first-child {
        /*font-size: 3.2rem;*/
    }

    .purchase-summary-total > span:nth-of-type(2) {
        font-size: var(--small-header-size);
    }

    .purchase-summary-total {
        flex-direction: column;
        width: fit-content;
        padding: 0;

    }

    .purchase-summary > button {
        display: inline-block;
        width: 28rem;
        height: fit-content;
        /*font-size: 3.2rem;*/
    }


    .purchase-body {
        height: fit-content;
        max-height: 45rem;
        width: 67.8rem;
        display: grid;
        align-items: center;
        flex-direction: column;
    }

    .cart-product-hide {
        max-height: 0;
    }

    .purchase-body > img {
        grid-row: 1/5;
        grid-column: 1/3;
        align-self: start;
        justify-self: center;
        width: 27.8rem;
        height: 27.8rem;
    }

    .purchase-inputs {
        display: flex;
        flex-direction: row;
        width: 23.4rem;
        height: fit-content;
        justify-self: center;
        align-self: center;
        grid-row: 5/10;
        grid-column: 1/3;
    }

    .purchase-inputs input {
        width: 66%;
        height: 9rem;
        /*font-size: 4rem;*/
    }

    .purchase-name {
        text-align: start;
        align-self: center;
        padding-top: .5rem;
        padding-left: .5rem;
        /*font-size: 3.9rem;*/
        grid-row: 1/3;
        grid-column: 3/7;
    }

    .plus {
        width: 17%;
    }

    .subtract {
        width: 17%;
    }

    .show-product-details {
        align-self: start;
        grid-row: 3/4;
        grid-column: 3/6;
        /*font-size: 3.2rem;*/
    }

    .purchase-price-details {
        align-self: start;
        grid-row: 4/10;
        grid-column: 3/7;
        align-content: start;
        margin: 0;
        text-align: start;
    }

    span.purchase-price {
        /*font-size: 4rem;*/
        text-align: start;
    }

    .purchase-total-container > span:nth-of-type(1) {
        /*font-size: 2.6rem;*/
    }

    .purchase-delete {
        bottom: 10%;
        left: 45%;
        transform: unset;
        /*font-size: 3.2rem;*/
    }

    .purchase-summary-container {
        padding: 0 0 2rem 0;
        background-color: white;
        position: fixed;
        bottom: 0;
        margin: 0;
        height: 15vh;
        width: 100vw;
        opacity: 85%;
    }

    .purchase-summary {
        height: 100%;
        width: 100%;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 0;
        margin: 0;
        box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .purchase-summary-legend {
        position: absolute;
        top: 0;
        left: 0;
        /*font-size: 2.4rem;*/
        transform: translateY(-100%);
        background-color: rgba(225, 225, 225, 50%);
        padding: 2rem;
        width: 100%;
        height: fit-content;
    }

    .user-info-form {
        margin: 0 0 2rem;
        padding: 2rem 4rem;
        height: fit-content;
        width: 100vw;
        box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .user-info-form-container {
        height: fit-content;
        width: 100%;
        padding-bottom: 2rem;
    }

    .back-button {
        width: 4rem;
        top: 0;

    }
}

@media screen and (min-width: 750px) {

    .shopping-car small {
        font-size: var(--x-small-font-size);
        font-weight: lighter;
    }

    .shopping-car span,
    .shopping-car p,
    .shopping-car a,
    .shopping-car label,
    .shopping-car input,
    .shopping-car button {
        font-size: var(--small-font-size);
    }

    .shopping-car h2 {
        font-size: var(--large-font-size);
    }

    .shopping-car h1 {
        font-size: var(--x-small-header-size);
    }

    .shopping-car {
        justify-content: center;
    }

    span.product-header-productline {
        font-size: var(--small-font-size);
    }

    .purchase-summary {
        gap: 3rem;
    }

    .purchase-summary > button {
        font-size: var(--small-font-size);
        padding: 1rem 0 1rem 0;
    }

}

@media screen and (min-width: 750px) and (max-width: 850px) {
    .product-in-shopping-car {
        margin-left: 5rem;
        width: 95%;
    }

    .purchase-body {
        width: 96%;
    }

    .purchase-price-details {
        width: 55%;
    }

    .large-line {
        width: 105%;
    }
}

@media screen and (min-width: 750px) and (max-width: 1299px) {

    .shopping-car-list-container {
        width: 95%;
    }

}

@media screen and (min-width: 1300px) and (max-width: 1599px) {

    .shopping-car small {
        font-size: var(--small-font-size);
        font-weight: lighter;
    }

    .shopping-car h1 {
        font-size: var(--small-header-size);
    }

    span.product-header-productline {
        font-size: var(--medium-font-size);
    }
}

@media screen and (min-width: 1600px) {

    .shopping-car small {
        font-size: var(--small-font-size);
        font-weight: lighter;
    }

    .shopping-car span,
    .shopping-car p,
    .shopping-car a,
    .shopping-car label,
    .shopping-car button {
        font-size: var(--large-font-size);
    }

    .shopping-car input {
        font-size: var(--medium-font-size);
    }

    .shopping-car h2 {
        font-size: var(--xx-large-font-size);
    }

    .shopping-car h1 {
        font-size: var(--medium-header-size);
    }

    span.product-header-productline {
        font-size: var(--x-large-font-size);
    }
}

@media screen and (min-width: 1900px) {

    .shopping-car small {
        font-size: var(--medium-font-size);
        font-weight: lighter;
    }

    .shopping-car strong {
        font-size: var(--xx-large-font-size);
    }

    .shopping-car h2 {
        font-size: var(--medium-header-size);
    }

    .shopping-car h1 {
        font-size: var(--large-header-size);
    }
}

