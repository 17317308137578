
.landing-page-section-tittle {
    color: #ffffff;
    margin-bottom: 9.7rem;
    text-align: center;
}


section::before {
    content: "";
    display: block;
    height: 11vh;
    margin-top: -11vh;
    visibility: hidden;
    pointer-events: none;
}


.landing {
    display: flex;
    flex-direction: column;
    gap: 30rem;
}

.background-img {
    position: absolute;
    background-image: url("../../../assets/images/GranjaDeFondo.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.imgs-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100vh - var(--navbar-height));
}

.imgs-container div {
    width: 25%;
    position: relative;
}

.imgs-container div img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: inline-block;
    object-fit: cover;
    object-position: top;
    filter: brightness(60%);
    position: absolute;
}


.imgs-container div:hover > * {
    filter: brightness(100%);
    transition: all .7s;
}

.imgs-container div div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.animal-icon {
    width: 24.5rem;
    height: 24.5rem;
    fill: white;
    fill-opacity: 0;
    stroke: white;
    stroke-width: 8px;
}

.animal-icon:hover {
    stroke: #99be14;
    transition: all .3s;
    cursor: pointer;
}

.animal-icon:hover + .animal-img {
    filter: brightness(100%);
}

.animal-icon, .animal-tittle {
    color: white;
    margin-bottom: 0;
}

/*
WIP
Work in progress
Por terminar
*/

.laboratories-banner {
    align-self: center;
    width: 80%;
    height: fit-content;
    margin-top: -25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.laboratories-banner-img-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 2rem;
    gap: 2.5rem;
    justify-content: center;
    flex-wrap: wrap;
}

.laboratories-banner img {
    width: 35rem;
    height: 20rem;
    text-align: center;
}

.laboratories-banner img:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all .3s;
}

/*Termina WIP */


.productos-destacados {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

}

.pd-wrapper {
    width: 95vw;

    position: relative;
}

.pd-wrapper .pd-carousel {
    white-space: nowrap;
    height: 60rem;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    scroll-behavior: smooth;
}

.pd-carousel.dragging {
    scroll-behavior: auto;
    cursor: grab;
}


.pd-left-button, .pd-right-button {
    width: 5rem;
    height: 5rem;
    color: white;
    stroke-linecap: round;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);


}

.pd-left-button:hover, .pd-right-button:hover {
    color: var(--on-hover-color);
    cursor: pointer;
}

.pd-left-button {
    left: -5%;
    display: none;
    z-index: 1;

}

.pd-right-button {
    right: -5%;

    z-index: 1;
}

.product-card {
    width: fit-content !important;
}


.card {
    width: 33.7rem;
    height: 58.3rem;
    background-color: white;
    display: flex;
    justify-content: center;
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    z-index: 1;
    border: 1px black solid;
}

.pd-card-wrapper {
    min-width: calc(100% / 4);
    display: flex;
    justify-content: center;
}


.card-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.card-content img {
    width: 24.6rem;
    height: 25.6rem;
    user-select: none;
    -webkit-user-drag: none;
}

.card-name {
    padding: .5rem;
    text-align: center;
    overflow-wrap: break-word;
    white-space: break-spaces;
    color: #3E3936;
    transition: .3s all;
}

.card-name:hover {
    color: #99be14;
    text-decoration-color: #99be14;
}

.product-price {
    color: #3E3936;
}

.product-weight {
    color: #909090;
}

.card-content button, .outstanding-section-button {
    width: 18.9rem;
    height: 5rem;
    background-color: #3E3936;
    color: #f0f0f0;
    border-radius: 2rem;
    border: none;
    align-self: center;
}

.card-content button:hover,
.outstanding-section-button:hover {
    background-color: var(--on-hover-color);
    cursor: pointer;
    transition: .3s;
    color: white;
}


.outstanding-section-button {
    margin-top: 6rem;
    background-color: #fff;
    color: #3E3936;
    width: 25.1rem;
}

.link-to-products {
    align-self: center;
}


/********************************************/

/*********** Producto individual ***********/

/********************************************/

.producto-individual {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.product-img-and-description {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68rem;
    height: 46rem;
    background-color: #f1f1f1;
    border-radius: 5rem;
}

.product-img-and-description img {
    width: 40.4rem;
    height: 40.4rem;
}


/********************************************/

/*********** Servicios ***********/

/********************************************/

.landing-service-section {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.landing-services-container {
    width: 80vw;
    height: 65vh;
    gap: 2%;
    display: grid;

    justify-items: center;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr 1fr;
    perspective: 90rem;
}

.landing-services-service,
.landing-services-service-desktop {
    width: 40rem;
    height: 25rem;
    position: relative;
    perspective: 90rem;
}

.service-active {
    cursor: pointer;
    transform: rotateY(180deg);
    position: relative;
    transition: 0.3s all;
}

.landing-services-service-desktop:hover .landing-services-service-container {
    cursor: pointer;
    transform: rotateY(180deg);
    position: relative;
    transition: 0.3s all;
}

.landing-services-service-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 2rem 1rem;
    border-radius: 3px;
    z-index: 1;
    text-align: center;
    transition: all 1s ease-out;
    transform-style: preserve-3d;
}

.loading-services-service-front, .loading-services-service-back {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}


.loading-services-service-front {
    cursor: pointer;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: visible;
    background-size: cover;
    background-position: center;
}


.loading-services-service-back {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 80%);

}

.loading-services-service-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding: 1rem 2rem;
}

.loading-services-service-content > h3 {
    color: white;
    text-align: start;
}


.landing-services-service-info {
    text-align: start;
    color: white;
    z-index: 9;
}


.landing-services-container :nth-child(1) {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}

.landing-services-container :nth-child(2) {
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}

.landing-services-container :nth-child(3) {
    grid-column: 5 / 7;
    grid-row: 1 / 2;
}

.landing-services-container :nth-child(4) {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
}

.landing-services-container :nth-child(5) {
    grid-column: 4 / 6;
    grid-row: 2 / 3;
}


.nosotros-section {
    margin-bottom: 15rem;

}

.services-container {
    width: 90vw;
    height: fit-content;
    /*background-color: white;*/
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 1fr;
}


.service {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    transition: .3s all;
}

.service:hover {
    color: #99be14;
}

.service > img {
    width: 23.5rem;
    height: 23.5rem;
    border-radius: 50%;
    margin-bottom: 2rem;
    transition: .3s all;
    cursor: pointer;
}

.service > img:hover {
    transform: scale(110%);
}

.service span {
    color: inherit;
    text-align: center;
}

.nosotros-card {
    width: 80vw;
}


.nosotros-container {
    display: flex;
    justify-content: center;
    height: fit-content;
}

.nosotros-carousel {
    width: 85vw;
}

.nosotros-card {
    height: 40vh;
    width: 100%;
    display: flex;
}

.video-corporativo {
    height: 100%;
    width: 100%;
}

.nosotros-info {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1.5rem;

    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 0;

}

.nosotros-info span, .nosotros-info h2, .nosotros-info-title {
    color: white;
}

.nosotros-info p, .nosotros-info li {
    color: white;
    text-align: start;
}

.nosotros-info li {
    line-height: 2;
}


.slick-slide img {
    display: inline-block;
    width: 40rem;
    height: 40rem;
    flex-shrink: 0;
}

/*Quitar cuando salga el resto de la página*/


.footer-up-hidden {
    display: none !important;
}

/********************************************/

/****************** Media Q ******************/

/********************************************/


@media screen and (min-width: 350px) {
    section {
        padding-top: 12vh;
    }


    .pd-card-wrapper {
        min-width: calc(100% / 1);
        display: flex;
        justify-content: center;
        width: 10rem;
        padding: 0rem 3rem;

    }

    .pd-wrapper .pd-carousel {
        height: 90rem;
    }

    .pd-wrapper {
        width: 60rem;

    }

    .pd-left-button {
        left: 0%;
    }

    .pd-right-button {
        right: 0%;
    }


    .card {
        transform: scale(1.5);
    }

    .services-container {
        display: grid;
        grid-template-rows: repeat(6, 20rem);
        grid-template-columns: 1rem 1fr 1fr 1fr 1fr 1fr;
        row-gap: 4rem;
    }


    button.slick-prev::before, button.slick-next::before {
        color: var(--original-light-green);
        height: 3rem;
        width: 3rem;
    }

    .nosotros-container .carousel.carousel-slider {
        overflow: visible;
    }


    .nosotros-info {
        height: fit-content;
        max-height: 45vh;
        overflow: auto;
    }


    .nosotros-card {
        margin: auto;
        height: 60vh;
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: 4rem;
    }

    .nosotros-info {
        width: 85%;
        padding-left: 0;
    }

    .nosotros-info ul {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        text-transform: unset;
    }

    .nosotros-card p, .nosotros-card li {
        font-size: var(--small-font-size);
    }


    .imgs-container {
        flex-wrap: wrap;
    }

    .imgs-container div {
        width: 50%;
    }


    .values-value-tittle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 2rem;
    }

    .values-icon {
        fill: white;
        height: 5rem;
        width: 5rem;
    }

    .values-paragraph {
        margin-bottom: 1rem;
    }

    /*Footer*/
    .footer-head {
        padding: 1rem 0rem 1rem 4rem;
    }

    .footer-head > span {
        font-size: 2.5rem;
    }

    .footer-head div > span {
        font-size: 2.0rem;
    }

    .footer-head a {
        font-size: 2.0rem;
    }

}

@media screen and (min-width: 350px) and (max-width: 749px) {

    h3 {
        font-size: var(--x-small-header-size);
    }

    span, p, a, button {
        font-size: var(--small-font-size);
    }

    .landing {
        gap: 10rem;
    }

    .animal-tittle {
        font-size: var(--small-header-size);
    }

    .landing-page-section-tittle {
        font-size: var(--small-header-size);
    }

    .landing-services-container {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        align-items: center;
        gap: 4rem;
    }


    .landing-services-service {
        width: 60rem;
        height: 37.5rem;
    }


    .nosotros-carousel {
        width: 80vw;
    }

    .nosotros-info-title {
        font-size: var(--x-small-header-size);
    }

    .footer-head {
        grid-template-columns: repeat(6, 1fr);
    }

    .footer-head div > span {
        font-size: var(--medium-font-size);
        color: black;
    }

    .footer-up-contact-us {
        display: flex;
        align-items: center;
        grid-column: 1/4;
        grid-row: 3/5;
        margin-left: 2rem;
        transition: .3s all;
    }

    .footer-up-arrow {
        transition: inherit;
        margin-left: 1rem;
        width: 2.5rem;
        height: 2.5rem;
    }

    .footer-up-arrow-active {
        transform: rotate(-90deg);
        fill: #99be14;
    }

    .footer-up-ubicanos {
        transition: inherit;
        margin: 0;
    }

    .footer-head div > .footer-up-ubicanos-active {
        color: #99be14;
    }

    .footer-up-contact-us-info-container {
        height: 25vh;
        width: 70vw;
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: center;
        top: 50%;
        left: 0%;
        transform: translate(-100%, -50%);
        background-color: rgba(255, 255, 255, 100%);
        pointer-events: none;
        opacity: 0;
        transition: .3s all;
    }

    .footer-info-active {
        pointer-events: all;
        left: 0%;
        transform: translate(0%, -50%);
        opacity: 1;
    }

    .footer-up-contact-us-info-container > div {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-evenly;
        gap: 1rem;
        height: 100%;
        width: 100%;
        padding: 1rem 1.5rem;
        position: relative;
    }


    .correo-container {
        display: flex;
        align-items: center;
    }

    .footer-up-mail-div {
        display: flex;
        flex-direction: column;
        justify-self: start;
        margin: 0;
        gap: 1rem;
    }

    .footer-up-phone-div {
        display: flex;
        align-items: center;
    }

    .close-info-button {
        position: absolute;
        top: 2px;
        right: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4.5rem;
        border: none;
        background-color: white;
    }

    .enlace-de-certificacion {
        display: grid;
    }


    .esr-logo {
        width: 16rem;
        height: 8.43rem;
        grid-column: 1/2;
        grid-row: 1/2;
        justify-self: start;
    }

    .senesica-logo {
        grid-column: 5/7;
        grid-row: 1/2;
        justify-self: start;
        width: 16.8rem;
        height: 5.32rem;
    }

    .sader-logo {
        width: 17rem;
        justify-self: center;
        grid-column: 2/5;
        grid-row: 1/2;
    }

    .footer-company-policies {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        grid-column: 4/6;
        grid-row: 3/5;
    }

    .aviso-de-privacidad {

        font-size: 2rem;
    }
}


@media screen and (min-width: 750px) {

    span, p, a {
        font-size: var(--x-small-font-size);
    }

    button {
        font-size: var(--small-font-size);
    }

    strong {
        font-size: var(--x-small-header-size);
    }

    h2 {
        font-size: var(--small-header-size);
    }

    h3 {
        font-size: var(--xx-small-header-size);
    }

    .animal-tittle {
        font-size: var(--small-header-size);
    }


    section {
        padding-top: 14vh;
    }

    .phone-nav {
        display: none;
    }


    .imgs-container div {
        width: 25%;
    }

    .links a {
        font-size: 3rem;
    }

    .services-container {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr 1fr;
    }

    .modal-container {
        width: 85.2rem;
        height: 43.7rem;
    }

    .modal-container img {
        display: block;
        width: 27rem;
        height: 27rem;
    }

    .modal-info {
        padding: 0rem 6rem 0 0;
    }

    .pd-wrapper {
        width: 108rem;
    }

    .pd-card-wrapper {
        min-width: calc(100% / 2);
    }

    .nosotros-card {
        flex-direction: row;
        padding: 4rem;
        width: 100%;
    }

    .nosotros-carousel .carousel .control-next.control-arrow {
        right: 0px;
    }

    .nosotros-carousel .carousel .control-prev.control-arrow {
        left: 0px;
    }


    .pd-right-button {
        right: -2%;
    }

    .pd-left-button {
        left: -2%;
    }

    .nosotros-card {
        align-items: center;
        justify-content: center;
    }


    .nosotros-info {
        height: fit-content;
        max-height: 50rem;
        width: 70%;
    }

    .nosotros-info > img {
        width: 50%;
    }


    .nosotros-info::-webkit-scrollbar {
        display: none;
    }

    .footer-up-arrow, .close-info-button {
        display: none;
    }

    .footer-head > .footer-up-contact-us {
        flex-direction: column;
        align-items: start;
        justify-content: center;
        height: 100%;
        grid-column: 1/3;
        grid-row: 1/6;
    }

    .footer-head div > span {
        color: black;
    }

    .footer-up-contact-us-info-container > div {
        margin-top: 1rem;
        margin-left: 3rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 100%;
    }


    .footer-up-mail-div {
        gap: 1rem;
    }

    .esr-logo {
        grid-column: 4/5;
        grid-row: 1/2;
        justify-self: center;
        width: 21rem;
        height: 10.43rem;
    }

    .senesica-logo {
        grid-column: 5/6;
        grid-row: 1/2;
        justify-self: start;
        width: 33.6rem;
        height: 10.64rem;
    }

    .sader-logo {
        width: 20rem;
        height: auto;
        grid-column: 3/4;
        grid-row: 1/2;
        justify-self: end;
    }


    .footer-company-policies {
        display: flex;
        flex-direction: row;
        width: fit-content;
        justify-self: start;
        gap: 2.5rem;
        grid-row: 4/5;
        grid-column: 5/6;
    }


    .aviso-de-privacidad {
        font-size: 2rem;
    }

}


@media screen and (min-width: 1024px) {

    .pd-wrapper {
        width: 162rem;
    }

    .pd-card-wrapper {
        min-width: calc(100% / 3);
        padding: 0rem 26rem;
    }

    .pd-wrapper .pd-carousel {
        height: 82rem;
    }

    .card {
        transform: scale(140%);
    }
}

@media screen and (min-width: 1000px) {
    .landing-services-service,
    .landing-services-service-desktop {
        width: 55rem;
        height: 34.37rem;
    }
}


@media screen and (min-width: 1300px) {

    h2 {
        font-size: var(--medium-header-size);
    }

    h3 {
        font-size: var(--x-small-header-size);
    }

    span, p, a {
        font-size: var(--small-font-size);
    }

    strong {
        font-size: var(--large-font-size);
    }


    .pd-wrapper .pd-carousel {
        height: fit-content;
    }

    .card {
        transform: scale(100%);
    }

    .pd-wrapper {
        /*width: 180rem;*/
        width: 144rem;
    }

    .pd-card-wrapper {
        min-width: calc(100% / 4);
        padding: 0rem 18rem;
    }
}

@media screen and (min-width: 1600px) {

    .animal-tittle, h2 {
        font-size: var(--large-header-size);
    }

    h3 {
        font-size: var(--large-font-size);
    }

    .nosotros-info-title {
        font-size: var(--x-large-font-size);
    }

    p, a, span, button, li {
        font-size: var(--medium-font-size);
    }

    .landing-services-service,
    .landing-services-service-desktop {
        width: 40rem;
        height: 25rem;
    }

    .nosotros-card p, .nosotros-card li {
        font-size: var(--large-font-size);
    }
}

@media screen and (min-width: 1900px) {


    .pd-wrapper {
        width: 180rem;
    }

    .pd-card-wrapper {
        min-width: calc(100% / 5);
        padding: 0rem 18rem;
    }

    /********************************************
    *******************************************

    PT TESTING / BORRAR

    *******************************************
    ********************************************/

    .alarma {
        padding: 4rem 2rem;
        background-color: #99be14;
        width: fit-content;
        color: white;
        font-size: var(--x-large-font-size);
    }

    .get {
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 25vw;
        gap: 5rem;
    }

    .post {
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        flex-direction: row;
        align-items: start;
        height: 40vw;
        gap: 5rem;
    }

    .pt-product {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        width: fit-content;
        height: fit-content;
        border: 2px solid black;
    }

    .pt-name {
        font-size: var(--medium-header-size);
        color: var(--original-light-green);
    }

    .pt-id {
        font-size: var(--medium-header-size);
        color: dodgerblue;
    }

    .pt-description, .pt-price, .pt-discount {
        font-size: var(--large-font-size);
    }


}






