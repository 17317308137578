.desktop-navbar a, .phone-navbar a {
    color: black;
}

.phone-nav {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 10vh;
    background-color: #fff;
}

.phone-nav-container {
    width: 100%;
    height: 100%;
    padding: 0rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.phone-logo {
    width: 15rem;
    display: inline-block;
}

.slide-menu-button {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    height: 6rem;
    width: 6rem;
}

.menu-button-active {
    gap: 0;
    z-index: 999;
}

.menu-icon-stroke {
    height: 2px;
    width: 4rem;
    background-color: black;
    transition: 0.3s all;
}

.menu-stroke-active {
    z-index: 99;
    position: absolute;
}

.menu-button-active div:first-of-type {
    opacity: 0;
}

.menu-button-active div:nth-of-type(2) {
    transform: rotate(-45deg);
}

.menu-button-active div:nth-of-type(3) {
    transform: rotate(45deg);
    background-color: black;
}


.menu-icon {
    stroke-width: .5rem;
    color: black;
    width: 5.5rem;
    height: 5.5rem;

}

.phone-menu {
    position: fixed;
    top: var(--navbar-height);
    right: -100%;
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: white;
    transition: right 0.3s;
    overflow-y: auto;
    color: black;
    z-index: 999999999;
    gap: 12rem;
}

.phone-menu-link-list {
    width: 100%;
    height: fit-content;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    list-style: none;
}

.phone-menu-pages-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.links-component-container {
    position: relative;
}

.trigger-container {
    display: flex;
    align-items: center;
}

.arrow {
    height: 2.5rem;
    width: 2.5rem;
    transform: rotate(-90deg);
}

.active {
    fill: var(--on-hover-color);
    transform: rotate(0);
    transition: .3s all;
    color: #99be14;
}

.pages-tittle {
    margin-left: 1rem;
    font-size: 3rem;
    padding: 1rem 1rem 1rem 0;
    width: fit-content;
}

.pages-tittle:hover {
    cursor: pointer;
    color: var(--on-hover-color);
    transition: all .3s;
}

.links-container {
    height: 0;
    width: 0;
    opacity: 0;
    padding: 2rem 2rem 2rem 2rem;
    border-radius: 1rem;
    background-color: white;
    position: absolute;
    /*top: 50%;*/
    top: calc(50% - .5rem);
    left: 0;
    display: flex;
    flex-direction: row;
    transition: .4s all;
    gap: 3rem;
    pointer-events: none;
}

.display-links {
    height: fit-content;
    display: flex;
    top: 100%;
    transform: translateY(0%);
    left: 0%;
    opacity: 100%;
    z-index: 1000;
    width: fit-content;
    pointer-events: all;
}

.pages-link-container {
    display: flex;
    flex-direction: row;
}

.page-link:hover {
    cursor: pointer;
}

.phone-menu-link-tittle {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: var(--x-large-font-size);
    padding: 1rem 2rem;
    border: 1px solid black;
    background-color: black;
    border-radius: 1rem;
    color: white;
}

.phone-menu-link {
    color: black;
    font-size: var(--x-large-font-size);
    text-decoration: none;
    text-underline: none;
}


.phone-menu.open {
    right: 0;
}


.closeButton {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 5px;
}

.navbar-contact-us-icons-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    min-height: 40rem;
}

.navbar-contact-us-icons-container > a > svg {
    width: 10rem;
    height: 10rem;
    stroke-width: 1px;
    stroke: var(--original-light-green);
}

.close-menu-btn {
    stroke-width: .5rem;
    color: black;
    width: 5.5rem;
    height: 5.5rem;
}

/*
.desktop-navbar {
    display: none;
    height: var(--navbar-height);
    width: 100%;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 9999;
}
*/


.desktop-navbar {
    position: sticky;
    top: 0;
    left: 0;
    height: var(--navbar-height);
    width: 100%;
    background-color: #fff;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
}


.background-img {
    content: "";
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: absolute;
    z-index: -1;
    top: 13vh;
    bottom: 0;
    right: 0;
    left: 0;
}

.background-img::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
}


.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 2rem;
}

.logo:hover {
    cursor: pointer;
}

.logo-and-links-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    width: fit-content;
    height: 100%;
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.links {
    display: flex;
    align-items: center;
    gap: 3rem;
}

/* Elementos individuales */
.logo:first-child {
    width: 13.2rem;
    height: 6.6rem;

}

.links a, .nav-link {
    padding: .5rem;
    text-decoration: none;
    position: relative;
}

.links a:hover, .nav-link:hover {
    cursor: pointer;
    color: var(--on-hover-color);
    transition: .2s;
}

.nav-link {
    font-size: 3rem;
}

.links a:hover::after {
    width: 100%;
}

.favorites-icon {
    width: 3.5rem;
    height: 3.5rem;
    color: black;
    transition: .15s all;
}

.favorites-icon:hover {
    cursor: pointer;
    fill: #e15773;
    color: #e15773;
}

.input {
    width: 25.8rem;
    height: 4.5rem;
    padding: 0 .5rem 0 0.5rem;
    border-radius: 0.8rem;
    border: 1px solid #c0c0c0;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
    font: inherit;
    font-size: 1.8rem;
}

.input:focus {
    border: 1px solid var(--on-hover-color);
    transition: .3s;
    outline: none;

}

.icons {
    display: flex;
    align-items: center;
    gap: 2rem;
    height: fit-content;
    margin-right: 3rem;
}

.shopping-car-icon-container {
    position: relative;
    width: 4.5rem;
    height: 4.5rem;
}

.carrito-icon {
    height: 3.5rem;
    width: 3.5rem;
}


.products-in-carrito-span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    top: 2rem;
    right: 3.7rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: black;
    z-index: 9999;
    cursor: default;
}

.carrito-icon:hover {
    cursor: pointer;
    fill: #99be14;
    transition: .2s;

}


.search-icon {
    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%);
}


@media screen and (min-width: 350px) and (max-width: 749px) {

    .products-in-carrito-span {
        color: black;
        font-size: var(--small-font-size);
        top: 0;
        right: 0;
        transform: translate(20%, -80%);
        background: none;
        border-radius: 0;
    }
}

@media screen and (min-width: 750px) {
    .products-in-carrito-span {
        color: black;
        font-size: var(--small-font-size);
        top: 0%;
        right: 0%;
        transform: translate(20%, -80%);
        background: none;
        border-radius: 0;
    }
}


@media screen and (min-width: 1300px) {
    .products-in-carrito-span {
        background-color: black;
        color: white;
        font-size: var(--x-small-font-size);
        width: 2.5rem;
        height: 2.5rem;
        top: 0;
        right: 0;
        transform: translate(20%, -40%);
        padding: .5rem;
        border-radius: 50%;
    }
}

@media screen and (min-width: 1300px) {
    .products-in-carrito-span {
        font-size: var(--small-font-size);
    }
}
