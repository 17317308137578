
.products-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    height: fit-content;
    min-height: 80vh;
}


.document-arrow {
    position: absolute;
    height: 90rem;
    right: 2rem;
    bottom: 2rem;
    padding: 1rem 2rem;
}

/*Aside*/

aside.filter-aside {
    position: sticky;
    width: 30vw;
    height: fit-content;
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 14vh 3rem 3rem 3rem;
    top: 0vh;
    left: 3rem;
}

.filter {
    width: 45rem;
    height: fit-content;
    max-height: 80vh;
    padding: 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: start;
    border-radius: 1.5rem;
    overflow: auto;
}

.filter::-webkit-scrollbar {
    width: 1rem;
    background-color: #f1f1f1;
    color: black;

}

.filter::-webkit-scrollbar-thumb {
    background-color: #99be14;
    height: 2rem;
    border-radius: 5rem;
}

.filter h2 {
    color: black;
    /*font-size: 2.3rem;*/
    margin-bottom: 2.5rem;
    text-align: start;
}

.filter-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.filter-input-container input {
    width: 70%;
    height: 4rem;
    border-radius: 5px 0 0 5px;
    border-color: black;

}

.filter-input-container span {
    box-sizing: border-box;

    background-color: black;
    height: 4rem;
    width: 4rem;
    padding: .7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /*font-size: 2.5rem;*/
    cursor: default;
    border-radius: 0 3px 3px 0;
}


.filter-input-container .magnifying-glass-span * {
    fill: white;
}

/*Main content*/

.main-content {
    height: fit-content;
    width: 75vw;
    display: flex;
    flex-direction: column;


}

.publicidad-container {

}

.publicidad-container img {
    width: 100%;
    height: 37rem;
    padding: 3rem;
    border-radius: 5rem;
}

.products-container {
    text-transform: uppercase;
    display: flex;
    align-items: start;
    flex-direction: column;
    padding: 3rem;
    gap: 3rem;
}

.catalogue-filter-title-container {
    display: flex;
    align-items: center;
    height: 5rem;
}

.catalogue-filter-title-container > h2 {
    color: white;
    margin: 0;
}

.product {
    height: 55.0rem;
    width: 33.8rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.product-down-body {
    height: 22.1rem;
    width: 33.8rem;
    background-color: var(--original-dark-green);
    padding: 1.5rem 2rem;
    position: relative;
}

.product-down-body-premium {
    height: 22.1rem;
    width: 33.8rem;
    background-color: #3E3936;
    padding: 1.5rem 2rem;
    position: relative;
}

.tooltip, .catalogue-price-disclaimer {
    text-transform: none;
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translate(-50%, -25%);
    padding: 5px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    pointer-events: none;
    white-space: nowrap;
    text-align: center;
    z-index: 999;
}

.catalogue-price-disclaimer {
    bottom: 25%;
    color: #c1c1c1;
}

.catalogue-disponibility-disclaimer {
    color: #c1c1c1;
}

.under-border {
    text-decoration: underline;
}

.catalogue-product-price {
    cursor: default;
    width: fit-content;

    color: white;
}

.tags {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: .5rem;
    display: flex;
    flex-direction: row;
}


.catalogue-products {
    display: flex;
    justify-content: start;
    gap: 3rem;
    width: 100%;
    flex-wrap: wrap;
}


.product-alarm {
    transition: .3s all;
    width: fit-content;
    position: fixed;
    text-align: center;
    border-radius: 5rem;
    left: 50%;
    padding: 1.5rem 3rem;
    z-index: 100;
    transform: translate(-50%, -100%);
}

.product-added-alarm {
    background-color: #99be14;
    color: white;
}

.product-rejected-alarm {
    background-color: #6dc0b7;
    color: white;
}

.active-alarm {
    transform: translate(-50%, 15%);
    /*transform: translate(-50%, 115%);*/
}


.product-top-body {
    height: 32.7rem;
    width: 33.8rem;
    background-color: white;
}

.product-top-body img {
    width: 100%;
    height: 100%;
    padding: 2rem;
}


.product-down-body span {
    display: block;
}

.product-tittle {
    width: fit-content;
    text-transform: uppercase;
    color: white;
}

.product-tittle a {
    color: white;
    text-decoration: none;
}

.product-tittle:hover {
    cursor: pointer;
    text-decoration: underline;
}

.catalogue-product-type {
    display: block;

    color: rgba(255, 255, 255, 70%);
}

.catalogue-product-content {
    display: block;

    color: rgba(255, 255, 255, 50%);
}


.add-button {

    z-index: 10;
    position: absolute;
    white-space: nowrap;
    top: -5px;
    left: 50%;
    padding: 1rem 2rem;
    height: 4.2rem;
    border-radius: 5rem;
    width: fit-content;
    border: none;
    transition: .3s all;
    transform: translate(-50%, -100%);
    box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.25);
    background-color: white;
    color: black;
    opacity: 85%;
}

.add-button:hover {
    transition: .3s all;
    background-color: #99be14;
    color: white;
}

.add-product-input-container {
    transition: .3s all;
    width: fit-content;
    height: fit-content;
    position: absolute;
    display: flex;
    top: -5px;
    left: -50%;
    transform: translate(-50%, -100%);
    box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.25);
    border-radius: 5rem;
    background-color: white;
}

.quantity-active {
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -100%);
}

.plus-icon, .minus-icon {
    width: 4rem;
    height: 4rem;
    padding: 1rem;
}

.add-product-input-container input {
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
    width: 12rem;
    height: 4.2rem;
    border: .2px none;
    padding-left: 1rem;
}

.add-product-input-container input:focus {
    outline: none;
    border: none;
}

.quantity-choosen-btn {
    transition: .3s all;
    height: 4.2rem;
    width: 4.2rem;
    box-shadow: 3px 0px 6px -3px rgba(0, 0, 0, 0.25);
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    cursor: pointer;
}

.valid-quantity {
    background-color: white;
    color: #99be14;
    border: 1px solid #99be14;
}

.valid-quantity:hover {
    background-color: #99be14;
    color: white;
}

.invalid-quantity {
    background-color: white;
    color: indianred;
    border: 1px solid indianred;
}

.invalid-quantity:hover {
    background-color: indianred;
    color: white;
}


.tags > * {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    border-radius: 50%;
    fill: white;
}

.product-type-icon {
    position: absolute;
    width: 2.8rem;
    height: 4.3rem;
    bottom: 0rem;
    right: 0rem;


}

.foco > .cls-1 {
    fill: #6d9838;
}

.estrella > .cls-1 {
    fill: #3E3936;
}


.wave-icon {
    position: absolute;
    width: 8rem;
    height: 8rem;
    bottom: 0;
    right: 0;
    fill: white;
}


.tooltip-icon {
    height: 1.5rem;
    width: 1.5rem;
    top: 5px;
    right: 5px;
    position: absolute;
    cursor: pointer;
}

/*Basta*/
@media screen and (min-width: 350px) and (max-width: 749px) {

    .product-alarm {
        font-size: var(--small-font-size);
        width: 75%;
    }

    .products-layout p,
    .products-layout a,
    .products-layout label {
        font-size: var(--small-font-size);
    }

    .products-layout span {
        font-size: var(--x-small-font-size);
    }

    .products-layout button, .products-layout input {
        font-size: var(--small-font-size);
    }

    .product-tittle, .product-tittle a {
        font-size: var(--small-font-size);
        line-height: var(--small-font-size);
    }

    .products-layout h3 {
        font-size: var(--small-font-size);
    }


    .main-content {
        width: 100%;
    }

    .products-layout {
        display: flex;
        flex-direction: column;
    }


    .main-content {
        height: fit-content;
        min-height: 75vh;
    }

    .add-button {
        width: 21.4rem;
        height: 5.5rem;

        top: -3px;
    }

    .quantity-active {
        height: fit-content;
        width: 100%;
        top: -0rem;
        left: 5rem;
        transform: translate(0, -100%);
    }


    .quantity-choosen-btn {
        height: 5rem;
        width: 5rem;
    }

    .add-product-input-container {
        width: 25rem;
        display: flex;
        align-items: center;
        height: 5rem;
        top: -3px;
    }

    .add-product-input-container > input {
        width: 12rem;
        height: 5rem;
    }

    .plus-icon {
        width: 4rem;
    }

    .minus-icon {
        width: 4rem;
    }

    .add-product-input-container > button {
        text-align: center;
        width: 5rem;
        height: 5rem;

    }

    .product-tittle {
        line-height: var(--medium-font-size);
    }

    aside.filter-aside {
        position: sticky;
        top: 10vh;
        left: 0;
        width: 100vw;
        padding: 0;
        z-index: 1000;
    }


    .filter {
        width: 100%;
        border-radius: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 3rem 0 3rem 0;
        gap: 2.5rem;
        z-index: 1000;
    }


    .filter-input-container {
        display: none;
    }

    .filter-input-container.phone {
        display: flex;
    }


    .magnifying-glass-span-phone {
        height: 5rem;
        width: 5rem;
        stroke-width: 2px;
        stroke: white;
        fill: white;
        background-color: black;
        padding: 1rem;
        border-radius: .5rem;
    }

    .mgsp-active {
        stroke: white;
        background-color: #99be14;
    }

    .phone-input-container {;
        transition: .3s transform;
        transform: translateY(-100%);
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 11rem;
        bottom: -11rem;
        left: 0;
        background-color: white;
        padding: 3rem 3rem 3rem 3rem;

    }

    .phone-input-container-activate {
        transform: translateX(0);
    }

    .phone-input {
        width: 90%;
        height: 5rem;
        border-radius: 1rem;
        border: 1px solid #C0C0C0;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);

    }

    .filter > h2 {
        display: none;
    }

    .filter h2 {
        margin: 0;
    }

    .products-container > h2 {
        text-align: start;
    }


    .catalogue-products {
        justify-content: center;
    }

    .active-alarm {
        transform: translate(-50%, 165%);
    }

}

@media screen and (min-width: 750px) {

    .products-layout p,
    .products-layout a,
    .products-layout label,
    .products-layout span {
        font-size: var(--x-small-font-size);
    }

    .products-layout button, .products-layout input {
        font-size: var(--x-small-font-size);
    }

    .product-alarm {
        font-size: var(--small-font-size);
    }

    .products-layout h3 {
        font-size: var(--small-font-size);
    }

    .products-layout h2 {
        font-size: var(--medium-font-size);
    }

    .catalogue-filter-title-container h1 {
        color: white;
        font-size: var(--x-small-header-size);
    }

    .product-tittle a {
        font-size: var(--small-font-size);
    }


    .phone-input-container {
        display: none;
    }

    .magnifying-glass-span-phone {
        display: none;
    }
}

@media screen and (min-width: 1024px) {
    .catalogue-products {
        justify-content: start;
    }
}

@media screen and (min-width: 1300px) {

    .products-layout p,
    .products-layout a,
    .products-layout label,
    .products-layout span {
        font-size: var(--small-font-size);
    }

    .products-layout button, .products-layout input {
        font-size: var(--small-font-size);
    }

    .product-tittle, .product-tittle a {
        font-size: var(--medium-font-size);
        line-height: var(--large-font-size);
    }

    .product-alarm {
        font-size: var(--medium-font-size);
    }

    .products-layout h3 {
        font-size: var(--medium-font-size);
    }

    .products-layout h2 {
        font-size: var(--large-font-size);
    }

    .products-layout h1 {
        font-size: var(--small-header-size);
    }
}


@media screen and (min-width: 1600px) {

    .products-layout p,
    .products-layout a,
    .products-layout label {
        font-size: var(--large-font-size);
    }

    .products-layout span {
        font-size: var(--medium-font-size);
    }

    .products-layout button, .products-layout input {
        font-size: var(--medium-font-size);
    }

    .products-layout h3 {
        font-size: var(--x-large-font-size);
    }

    .products-layout h2 {
        font-size: var(--xx-large-font-size);
    }

    .products-layout h1 {
        font-size: var(--medium-header-size);
    }

    .product-tittle {
        line-height: var(--x-large-font-size);
    }

}

@media screen and (min-width: 1900px) {


    .products-layout span {
        font-size: var(--large-font-size);
    }

    .product-tittle, .product-tittle a {
        font-size: var(--small-header-size);
        line-height: var(--medium-header-size);
    }

    .product-alarm {
        font-size: var(--large-font-size);
    }

}




